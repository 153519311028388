.rpb-slider-container {
		background: $brand-primary;
		display: flex;
		align-items: center;
		justify-content: center;
		.slide-arrow-positioner {
			-ms-flex-align: center;
			align-items: center;
			cursor: pointer;
			display: -ms-flexbox;
			display: flex;
			height: 2em;
			width: 2em;
			-ms-flex-pack: center;
			justify-content: center;
			opacity: 1;
			padding: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			box-sizing: border-box;
			border: 0;
			background: #ccc;
			border-radius: 50%;
			pointer-events: none;
		}
		.slide-arrow {
				cursor: pointer;
				height: 2em;
				width: 2em;
				opacity: 1;
				padding: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;
				box-sizing: border-box;
				border: 0;
			}
			.slide__next {
				right: 0;
				left: auto;
			}
			.slide__prev {
				left: 0;
				right: auto;
			}
		.rpb-slides {
			display: flex;
			overflow-x: scroll;
			position: relative;
			scroll-behavior: smooth;
			scroll-snap-type: x mandatory;
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
			&::-webkit-scrollbar {
			  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
			}
				.rpb-slide {
			    display: flex;
			    flex-shrink: 0;
			    width: 100%;
			    margin-right: 0px;
			    box-sizing: border-box;
			    transform-origin: center center;
			    transform: scale(1);
			    scroll-snap-align: center;
				}
		 }
}
